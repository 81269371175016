import React from 'react';

const CareerPage = () => {
  return (
    <div>
    <div class="center">
<video loop autoPlay muted playsInline>
<source src={require('../video/Proverbs1423.mp4')}  type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>
     <div className="content">
     <div className="row"> 

     {/* Left Column */}
     <div className="col-md-6">
        <h2>A pondering on one's career</h2>
        <p> <br/>
       
       Let's look at 3 things <br/>
       
       <br/>1.Meaning of the word 'Career'
       
       <br/>2.How God helped me in my Career?
       
       <br/>3.Some more considerations concerning the choice of a career
       <br/>

       <br/>1.Meaning of the word 'Career': One definition  - A job or profession that somebody has been trained for and does for a long time; the series of jobs that somebody has in a particular area of work. example - Sarah is considering a
        career in engineering.<br/>
      And another definition - the period of your life that you spend working. usage - She spent most of her career working in India.<br/>
       
       <br/>2.How God helped me in my Career? God helped me in choosing the Subjects of Study
through my loving Mother (Elective Mathematics in my S.S.L.C. (Standard XI) and through a caring Principal of a College in choosing Mathematics, Economics and Commerce in my P.U.C.
(Pre-University Course) taking into consideration my interest, aptitude and family circumstances and later in joining B.Com. Degree Course.With inputs a Professor gave in the final year of B.Com. course about various avenues available for higher education and job
opportunities available for B. Com. Graduates, I got interested in pursuing M.B. A. Course and tried to gather relevant additional information. I had an interest in Teaching and jobs were not so easy to come by at that time. Upon finishing M.B.A., I got the first job as an Assistant
Professor of Business Administration in a private aided college. About a year later I joined
a Group of Small Scale industrial undertakings. Two years later I again applied for and got Teaching Job in another private aided college. After three years I shifted to another private aided college where I continued to work for the next 30 years until retirement at age 58. Throughout my studies and Career, I prayed for and received God’s help in all respects including guidance, admissions and job openings in astonishing (miraculous) ways by the Grace of my Lord and Saviour Jesus Christ! One specific Word of Promise that God gave me and faithfully kept was, _ “If you, who are evil, know how to give good gifts to your children,
how much more will your Father Who is in heaven give good things to those who ask Him! (Matthew 7:7-11)<br/>

<br/>

3.Some more considerations concerning the choice of a career<br/>
<p style={{ textIndent: '20px' }}>
  1.One's interest in the field of work being considered<br/>
  <p style={{ textIndent: '20px' }}>
  2.Qualifications required for the successful performance in the job (by the potential employer) in terms of education, training, skills and experience
  </p>
   <p style={{ textIndent: '20px' }}>
  3.Qualifications the candidate possesses
  </p>
  <p style={{ textIndent: '20px' }}>
  4.The gap between 2 and 3 and how it may be bridged
  </p>
  <p style={{ textIndent: '20px' }}>
  5.The compensation package expected and offered
  </p>
  <p style={{ textIndent: '20px' }}>
  6.The potential for career progression - possible increases in pay and benefits, opportunities for promotion and transfers and training within the country and oversees.
  </p>
  <p style={{ textIndent: '20px' }}>
  And when a person is seeking his or her very first employment, it may be advisable to be more practical about his or her expectations, taking into consideration the prevailing condition in the job market in the specific industry, economy(country) and the world.
  </p>
  <p style={{ textIndent: '20px' }}>
  Above all considerations, One who believes in the ALL knowing and ALL powerful GOD can trust HIS goodness to provide him with what is best, because of HIS grace! After all the CREATOR knows that it is HIS WILL for everyone to work with his own hands to make a living and to provide for the needs of his wife and children
  </p>
  </p>
       </p>
          
          </div>
      <div className="col-md-6">
        <p>
          <img className="imges" src={require('../imgs/careerques.png')} alt="Let my people think, understand, apply." />
        </p>
      </div>
     </div>
      
       </div>

       <section className="hero-section">
       <img className="imgeshero" src={require('../imgs/careersuccess.png')} alt="I greet you with the great words, grace and peace!
We know the meaning of those words because Jesus Christ rescued us from this evil world we’re in by offering himself as a sacrifice for our sins.
God’s plan is that we all experience that rescue.
Glory to God forever! Oh, yes!
(From Introduction to Galatians & Chapter 1 from The Holy Bible - The Message version)
" />
        </section>
  </div>
  );
};

export default CareerPage;
