import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';
import FreedomPage from './components/FreedomPage';
import CareerPage from './components/CareerPage';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Freedom" element={<FreedomPage />} />
          <Route path="/Career" element={<CareerPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

// Header Component with Mega Menu for Top Navigation
function Header() {
  return (
    <header className="header">
      <div className="navbar">
     {/* Align all menu items to the left */}
     <div className="navbar-left">
        <Link to="/" className="nav-link">Home</Link>
        {/* Mega Menu Dropdown */}
        <div className="dropdown">
          <span className="nav-link">Slices of Life</span>
          <div className="dropdown-content">
            <div className="row">
              <div className="column">
                <h6 className="h6color">Explore</h6>
                <Link to="/Freedom">Freedom</Link>
              </div>
              <div className="column">
                <h6 className="h6color">Live</h6>
                <Link to="/Career">Career</Link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; Bethel International Ministries. All rights reserved.</p>
    </footer>
  );
}

export default App;
