import React from 'react';

const HomePage = () => {
  return (
    <div>
      <div class="center">
<video loop autoPlay muted playsInline>
  <source src={require('../video/John316.mp4')}  type="video/mp4"/>
  Your browser does not support the video tag.
</video>
</div>
       <div className="content">
       <div className="row"> 

       {/* Left Column */}
       <div className="col-md-6">
          <h2>Life is a gift on loan!</h2>
          <p>
            <br/>There was this guy named Nicodemus, a religious leader, who came to see Jesus one night. He started off by saying, "Jesus, we know you're a teacher from God, because no one can do the things you do without God's help."

            Jesus cut right to the point. He said, "You can't even see God's kingdom unless you're born again."

            Nicodemus was puzzled. "How can anyone be born again when they're old? Do they have to go back into their mother's womb and be born again?"

            Jesus clarified, "I'm talking about a spiritual birth, not a physical one. You need to be born of water and the Spirit. Flesh gives birth to flesh, but the Spirit gives birth to spirit. Don't be surprised that I say you must be born again."

            He continued, "Just as Moses lifted up the snake in the wilderness, the Son of Man must be lifted up, so that everyone who believes in him may have eternal life. God loved all the people in the world so much that he gave his one and only Son, so that everyone who believes in him will not perish but have eternal life. God sent his Son to the people in the world not to judge the people, but to save all the people in the world through Jesus. Anyone who believes in Jesus is not judged, but anyone who does not believe is already judged, because they have not believed in the name of the one and only Son of God."

            So, the bottom line is this: If you believe in Jesus, you have eternal life. If you don't, well, you're missing out.
            </p>
            
            </div>
        <div className="col-md-6">
          <p>
            <img className="imges" src={require('../imgs/appquestions.png')} alt="Let my people think, understand, apply.Who is the person of God? Why would he speak with me? Can I converse discuss with him? " />
          </p>
        </div>
       </div>
        
         </div>

         <section className="hero-section">
         <img className="imgeshero" src={require('../imgs/believeprayer1.png')} alt="My Prayer - Dear Lord Jesus,I believe in you. Speak to me please and help me have a conversation with you " />
          </section>
    </div>
  );
};

export default HomePage;
