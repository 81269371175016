import React from 'react';

const FreedomPage = () => {
  return (
      <div>
      <div class="center">
<video loop autoPlay muted playsInline>
  <source src={require('../video/Gal15.mp4')}  type="video/mp4"/>
  Your browser does not support the video tag.
</video>
</div>
       <div className="content">
       <div className="row"> 

       {/* Left Column */}
       <div className="col-md-6">
          <h2>A free life</h2>
          <p>
            <br/>When men and women get their hands on religion, one of the first things they often do is turn it into an instrument for controlling others, either putting or keeping them “in their place”. The history of such religious manipulation and coercion is long and tedious. It is little wonder that people who have only known religion on such terms experience release or escape from it as freedom. The problem is that the freedom turns out to be short-lived.
Paul of Tarsus was doing his diligent best to add yet another chapter to this dreary history when he was transformed by Jesus to something radically and entirely different – a free life in God.
Through Jesus, Paul learned that God was not an impersonal force to be used to make people behave in certain prescribed ways, but a personal Savior who set us free to live a free life. God did not coerce us from without, but set us free from within.
It’s essential for us to recover the original freedom. The book of Galatians in the Holy Bible gives us direction in the nature of God’s gift of freedom – most necessary guidance, for freedom is a delicate and subtle gift, easily perverted and often squandered.
            </p>
            
            </div>
        <div className="col-md-6">
          <p>
            <img className="imges" src={require('../imgs/appquesfreedom.png')} alt="Let my people think, understand, apply." />
          </p>
        </div>
       </div>
        
         </div>

         <section className="hero-section">
         <img className="imgeshero" src={require('../imgs/freegreetings.png')} alt="I greet you with the great words, grace and peace!
We know the meaning of those words because Jesus Christ rescued us from this evil world we’re in by offering himself as a sacrifice for our sins.
God’s plan is that we all experience that rescue.
Glory to God forever! Oh, yes!
(From Introduction to Galatians & Chapter 1 from The Holy Bible - The Message version)
" />
          </section>
    </div>


  );
};

export default FreedomPage;
